<template>
  <div class="cookies-banner align-items-center justify-content-center">
    <div>
      {{ $t('SHARED.COOKIES_BANNER.WEBSITE_USES_COOKIES') }}
      <NuxtLink
        qid="cookies-1"
        to="/privacy"
        target="_blank"
        class="privacy-policy-btn"
        >{{ $t('SHARED.COOKIES_BANNER.PRIVACY_POLICY') }}.</NuxtLink
      >
    </div>
    <div class="ms-3 ms-sm-4">
      <a
        qid="cookies-2"
        href="javascript:void(0);"
        class="btn btn-white-bg accept-btn"
        @click="acceptCookies()"
        >{{ $t('SHARED.COOKIES_BANNER.ACCEPT') }}</a
      >
    </div>
  </div>
</template>

<script setup>
const { acceptCookies } = useCookieBanner();
</script>

<style lang="scss" scoped>
.app-container {
  height: 100%;
}

.main-app {
  height: 100%;

  &.cookiesBannerVisible {
    padding-top: 72px;

    @media (min-width: 576px) {
      padding-top: 40px;
    }
  }
}

.cookies-banner {
  display: flex;
  flex-shrink: 0;
  background-color: #e6c84b;
  padding: 9px 16px;
  font-size: 12px;
  line-height: 16px;
  color: var(--cup-general-color-5);
  font-weight: 600;
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 72px;

  @include media-breakpoint-up(sm) {
    height: auto;
  }

  a {
    font-weight: bold;
    color: var(--cup-general-color-5);

    &.privacy-policy-btn {
      font-weight: 800;
      border-bottom: 1px solid;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    &.accept-btn {
      padding: 4px 12px;
      font-size: 12px;
      letter-spacing: 0.25px;
      line-height: 14px;
      font-weight: 800;
      color: var(--cup-main-color-1);
    }
  }
}
</style>
